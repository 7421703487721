import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/card"
import EncloseFloats from "../components/enclose-floats"
import { EntryHeader, EntryTitle } from "../components/entry"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page Not found" />
    <Card variant="primary">
      <EncloseFloats>
        <EntryHeader>
          <EntryTitle>404: Page Not Found</EntryTitle>
        </EntryHeader>
        <p>
          {
            "You're on a 404 page. No, not the improv group. You tried to visit a page that does not exist on our site and ended up here."
          }
        </p>
        <p>
          You may want to start on the <Link to="/">home page</Link> and see if
          you can find what you are looking for. Otherwise if you are sure
          something is broken you can use our{" "}
          <Link to="/website-feedback/">Website Feedback</Link> page.
        </p>
      </EncloseFloats>
    </Card>
  </Layout>
)

export default NotFoundPage
